/** @jsx jsx */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import shortid from 'shortid';
import Cookies from 'js-cookie';

import { jsx, Container, Flex, Select } from 'theme-ui';
import StoreContext from '../../context/StoreContext';
import HamburgerButton from './HamburgerButton';
import { media } from '../../utils/media';
import Menu, { MobileMenu } from './Menu';
import MenuItem from './MenuItem';

const StoreSelector = ({ stores, currentStore, handleStoreChange }) => (
  <select
    onChange={handleStoreChange}
    sx={{
      '::-ms-expand': { display: 'none' },
      appearance: 'none',
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      color: 'brand',
      pb: '1px',
    }}
  >
    {stores.map((store) => (
      <option
        key={shortid.generate()}
        value={store.value}
        selected={currentStore === store.value}
      >
        {store.label}
      </option>
    ))}
  </select>
);
StoreSelector.propTypes = {
  stores: PropTypes.array.isRequired,
  currentStore: PropTypes.string.isRequired,
  handleStoreChange: PropTypes.func.isRequired,
};

const Hamburger = styled(HamburgerButton)`
  display: inline-block;
  ${media.desktop`display: none;`}
`;

const Header = ({ data, menuStatus, toggleMenu, siteTitle, ...props }) => {
  const { checkout } = useContext(StoreContext);

  const { logo, headerNavigation, secondaryNavigation, headerBody } = data;

  const itemsInCart = checkout.lineItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const stores = [
    { label: 'UK', value: '' },
    { label: 'USA', value: 'en-us' },
  ];

  const isBrowser = typeof window !== 'undefined';
  let currentStore = '';
  if (isBrowser) {
    currentStore = window.location.href.split('/')[3];
  }

  const handleStoreChange = (e) => {
    if (isBrowser) {
      window.location.href = `/${e.target.value}`;
      Cookies.set('selectedStore', e.target.value, { expires: 7 });
    }
  };

  return (
    <Container
      as="header"
      bg="white"
      sx={{
        height: 'headerHeight',
        position: 'sticky',
        right: 0,
        top: 0,
        zIndex: 1000,
        borderBottom: 'lightGreyBorder',
      }}
    >
      {/* <HeaderRoot> */}
      <Flex
        as="nav"
        mx="auto"
        px={[3, 3, 0]}
        sx={{
          maxWidth: 'contentWidth',
          mx: 'auto',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Hamburger
          width={28}
          rotate={0}
          height={18}
          color="#5F0082"
          isOpen={menuStatus === 'open'}
          strokeWidth={3}
          borderRadius={1}
          animationDuration={0.2}
          menuClicked={toggleMenu}
        />

        <Flex alignItems="center">
          <Link
            to="/"
            sx={{
              display: 'block',
              lineHeight: 0,
              color: 'brand',
              mr: 4,
              height: '100%',
            }}
          >
            <Img fixed={logo.localFile.childImageSharp.fixed} />
          </Link>

          <Menu as="ul">
            {headerNavigation.map((item) => {
              const { label, link_url: linkURL } = item;
              return (
                <MenuItem key={shortid.generate()}>
                  <Link to={linkURL}>{label}</Link>
                </MenuItem>
              );
            })}
          </Menu>

          <MobileMenu as="ul" visible={menuStatus === 'open'}>
            {headerNavigation.map((item) => {
              const { label, link_url: linkURL } = item;
              return (
                <MenuItem key={shortid.generate()}>
                  <Link to={linkURL}>{label}</Link>
                </MenuItem>
              );
            })}
            {secondaryNavigation.map((item) => {
              const { label, link_url: linkURL } = item;
              return (
                <MenuItem key={shortid.generate()}>
                  <Link to={linkURL}>{label}</Link>
                </MenuItem>
              );
            })}
            <MenuItem>
              {/* <StoreSelector
                stores={stores}
                currentStore={currentStore}
                handleStoreChange={handleStoreChange}
              /> */}
            </MenuItem>
          </MobileMenu>
        </Flex>

        <Flex height="100%">
          <Menu as="ul">
            {secondaryNavigation.map((item) => {
              const { label, link_url: linkURL } = item;
              return (
                <MenuItem key={shortid.generate()}>
                  <Link to={linkURL}>{label}</Link>
                </MenuItem>
              );
            })}
            <MenuItem>
              {/* <StoreSelector
                stores={stores}
                currentStore={currentStore}
                handleStoreChange={handleStoreChange}
              /> */}
            </MenuItem>
          </Menu>
        </Flex>
      </Flex>
      {/* </HeaderRoot> */}
    </Container>
  );
};

Header.propTypes = {
  data: PropTypes.object.isRequired,
  siteTitle: PropTypes.string,
  menuStatus: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
