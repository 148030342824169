/** @jsx jsx */

import React from 'react';
import PropTypes from 'prop-types';
import { jsx } from 'theme-ui';

import CartListItem from './CartListItem';


const CartList = ({
  items,
  handleRemove,
  updateQuantity,
  setCartLoading,
  isCartLoading,
}) => (
  <>
    <div
      sx={{
        borderBottom: ({ colors }) => `1px solid ${colors.brandBright}`,
        display: 'flex',
        justifyContent: 'space-between',

        span: {
          color: 'textLight',
          flexBasis: 60,
          flexGrow: 0,
          fontSize: 1,
          paddingBottom: '8px',
          textAlign: 'center',

          '&:first-of-type': {
            flexGrow: 1,
            textAlign: 'left',
          },
        },
      }}
    >
      <span>Product</span>
      <span>Qty.</span>
      <span>Remove</span>
    </div>
    <ul
      sx={{
        listStyle: 'none',
        margin: 0,
        padding: 0,
      }}
    >
      {items.map((item) => (
        <CartListItem
          key={item.id}
          item={item}
          handleRemove={handleRemove(item.id)}
          updateQuantity={updateQuantity(item.id)}
          setCartLoading={setCartLoading}
          isCartLoading={isCartLoading}
        />
      ))}
    </ul>
  </>
);

CartList.propTypes = {
  items: PropTypes.array.isRequired,
  handleRemove: PropTypes.func.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  setCartLoading: PropTypes.func.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
};

export default CartList;
