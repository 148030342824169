/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/core';
import { graphql, StaticQuery } from 'gatsby';
import { Box } from 'theme-ui';

import StoreContext, { defaultStoreContext } from '../../context/StoreContext';
import InterfaceContext, {
  defaultInterfaceContext,
} from '../../context/InterfaceContext';
import Header from './Header';
import Footer from './Footer';
import Cart from '../Cart';
// import SEO from '../shared/SEO';
// import SideBar from './SideBar';
import { globalStyles } from '../../styles/global';
import MessageStrip from '../MessageStrip';
import RedirectPopup from '../RedirectPopup';

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interface: {
        ...defaultInterfaceContext,
        toggleCart: () => {
          this.setState((state) => ({
            interface: {
              ...state.interface,
              cartStatus:
                this.state.interface.cartStatus === 'open' ? 'closed' : 'open',
              menuStatus: 'closed',
            },
          }));
        },
        toggleMenu: () => {
          this.setState((state) => ({
            interface: {
              ...state.interface,
              menuStatus:
                this.state.interface.menuStatus === 'open' ? 'closed' : 'open',
            },
          }));
        },
      },
      store: {
        ...defaultStoreContext,
        addVariantToCart: (variantId, quantity) => {
          if (variantId === '' || !quantity) {
            console.error('Both a size and quantity are required.');
            return;
          }

          this.setState((state) => ({
            store: {
              ...state.store,
              adding: true,
            },
          }));

          const { checkout, client } = this.state.store;
          const checkoutId = checkout.id;
          const lineItemsToUpdate = [
            { variantId, quantity: parseInt(quantity, 10) },
          ];

          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then((checkout) => {
              this.setState((state) => ({
                store: {
                  ...state.store,
                  checkout,
                  adding: false,
                },
              }));
            });
        },
        addVariantsToCart: (lineItemsToUpdate) => {
          this.setState((state) => ({
            store: {
              ...state.store,
              adding: true,
            },
          }));

          const {
            store: { checkout, client },
          } = this.state;
          const checkoutId = checkout.id;

          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then((checkout) => {
              this.setState((state) => ({
                store: {
                  ...state.store,
                  checkout,
                  adding: false,
                },
              }));
            });
        },
        removeLineItem: (client, checkoutID, lineItemID) => {
          return client.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then((res) => {
              this.setState((state) => ({
                store: {
                  ...state.store,
                  checkout: res,
                },
              }));
            });
        },
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
          const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) },
          ];

          return client.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then((res) => {
              this.setState((state) => ({
                store: {
                  ...state.store,
                  checkout: res,
                },
              }));
            });
        },
      },
    };
  }

  componentDidMount() {
    this.initializeCheckout();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      if (this.state.interface.menuStatus === 'open') {
        this.state.interface.toggleMenu();
      }
    }
  }

  async initializeCheckout() {
    // Check for an existing cart.
    const isBrowser = typeof window !== 'undefined';
    const existingCheckoutID = isBrowser
      ? localStorage.getItem('shopify_checkout_id')
      : null;

    const setCheckoutInState = (checkout) => {
      if (isBrowser) {
        localStorage.setItem('shopify_checkout_id', checkout.id);
      }

      this.setState((state) => ({
        store: {
          ...state.store,
          checkout,
        },
      }));
    };

    const createNewCheckout = () => this.state.store.client.checkout.create();
    const fetchCheckout = (id) => this.state.store.client.checkout.fetch(id);

    if (existingCheckoutID) {
      try {
        const checkout = await fetchCheckout(existingCheckoutID);

        // Make sure this cart hasn’t already been purchased.
        if (!checkout.completedAt) {
          setCheckoutInState(checkout);
          return;
        }
      } catch (e) {
        localStorage.setItem('shopify_checkout_id', null);
      }
    }

    const newCheckout = await createNewCheckout();
    setCheckoutInState(newCheckout);
  }

  render() {
    const { children } = this.props;
    const { store } = this.state;

    return (
      <>
        <Global styles={globalStyles} />
        <RedirectPopup message="Choose a different region if you want to see the content for your location and shop online. We recommend:" />
        {/* <SEO title="Home" /> */}
        <StoreContext.Provider value={store}>
          <InterfaceContext.Provider value={this.state.interface}>
            <InterfaceContext.Consumer>
              {({ cartStatus, toggleCart, menuStatus, toggleMenu }) => (
                <StaticQuery
                  query={query}
                  render={(data) => {
                    const {
                      site: {
                        siteMetadata: { lang },
                      },
                    } = data;
                    const nodeData = data.allPrismicLayout.edges
                      .filter(({ node }) => node.lang === lang)
                      .map(({ node }) => node);
                    let layoutData = {};
                    if (nodeData.length > 0) {
                      [layoutData] = nodeData;
                    }
                    const {
                      copyright,
                      logo,
                      inverted_logo: invLogo,
                      header_navigation: headerNavigation,
                      secondary_navigation: secondaryNavigation,
                      header_body: headerBody,
                      footer_body: footerBody,
                    } = layoutData.data;

                    const headerData = {
                      logo,
                      headerNavigation,
                      secondaryNavigation,
                      headerBody,
                    };
                    const footerData = { logo: invLogo, footerBody, copyright };

                    const messageStrip = headerBody.find(
                      (slice) => slice.slice_type === 'messagestrip'
                    );

                    return (
                      <>
                        <Header
                          data={headerData}
                          menuStatus={menuStatus}
                          toggleMenu={toggleMenu}
                        />
                        {/* <SideBar status={menuStatus} /> */}
                        <div>
                          <Cart status={cartStatus} toggle={toggleCart} />
                          {messageStrip && <MessageStrip data={messageStrip} />}
                          <Box as="main" width={1}>
                            {children}
                          </Box>
                        </div>
                        <Footer data={footerData} />
                      </>
                    );
                  }}
                />
              )}
            </InterfaceContext.Consumer>
          </InterfaceContext.Provider>
        </StoreContext.Provider>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

export const query = graphql`
  query {
    site {
      siteMetadata {
        lang
      }
    }
    allPrismicLayout {
      edges {
        node {
          lang
          data {
            copyright
            logo {
              localFile {
                url
                childImageSharp {
                  fixed(height: 60) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            inverted_logo {
              localFile {
                url
                childImageSharp {
                  fixed(height: 60) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            header_navigation {
              label
              link_url
            }
            secondary_navigation {
              label
              link_url
            }
            header_body {
              ... on PrismicLayoutHeaderBodyButton {
                id
                primary {
                  action_label
                  action_url {
                    url
                    uid
                    type
                    target
                  }
                }
                slice_type
              }
              ... on PrismicLayoutHeaderBodyMessagestrip {
                id
                slice_type
                primary {
                  message {
                    html
                  }
                  text_color
                  background_color
                }
              }
            }
            footer_body {
              ... on PrismicLayoutFooterBodyNavItem {
                id
                primary {
                  label
                }
                items {
                  label
                  link_url {
                    url
                    type
                    uid
                    target
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
  }
`;
