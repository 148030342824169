/** @jsx jsx */

import React from 'react';
import PropTypes from 'prop-types';
import { jsx, Box, Flex } from 'theme-ui';

const CountdownDisplay = (props) => {
  const { years, months, days, asDays, hours, minutes, seconds } = props;

  return (
    <time
      sx={{ ml: 3, pl: 3, borderLeft: '1px solid #FFF' }}
      dateTime={`P${asDays}DT${hours}H${minutes}M${seconds}S`}
    >
      <span sx={{ mr: 3 }}>Offer ends in</span>
      {years && <span>{String(years).padStart(2, 0)} years</span>}
      {months && <span>{String(months).padStart(2, 0)} months</span>}
      {days && <span>{String(days).padStart(2, 0)} days</span>}
      <span>
        <strong>{String(hours).padStart(2, 0)}</strong>h{' '}
      </span>
      <span>
        <strong>{String(minutes).padStart(2, 0)}</strong>m{' '}
      </span>
      <span>
        <strong>{String(seconds).padStart(2, 0)}</strong>s
      </span>
    </time>
  );
};

CountdownDisplay.propTypes = {
  years: PropTypes.number.isRequired,
  months: PropTypes.number.isRequired,
  days: PropTypes.number.isRequired,
  asDays: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
};

class Countdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { now: new Date() };
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState({ now: new Date() });
    }, this.props.intervalDelay);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const { now } = this.state;
    const { toDate } = this.props;
    const delta = toDate - now;

    return this.props.children({
      years: Math.floor(delta / 1000 / 60 / 60 / 24 / 30 / 12),
      months: Math.floor((delta / 1000 / 60 / 60 / 24 / 30) % 12),
      days: Math.floor((delta / 1000 / 60 / 60 / 24) % 30),
      hours: Math.floor((delta / 1000 / 60 / 60) % 24),
      minutes: Math.floor((delta / 1000 / 60) % 60),
      seconds: Math.floor((delta / 1000) % 60),
      milliseconds: delta,
      asDays: Math.floor(delta / 1000 / 60 / 60 / 24),
    });
  }
}

Countdown.propTypes = {
  toDate: PropTypes.instanceOf(Date).isRequired,
  intervalDelay: PropTypes.number,
};

Countdown.defaultProps = {
  intervalDelay: 1000,
};

const MessageStrip = ({ data }) => {
  const {
    message,
    text_color: color,
    background_color: background,
  } = data.primary;

  const endsAt = new Date();
  endsAt.setHours(23, 59, 59, 999);

  return (
    <Flex
      sx={{
        background:
          background || 'linear-gradient(45deg, #45166a, #83157b 75%, #bc158a)',
        color: color || 'white',
        py: 3,
        textAlign: 'center',
        fontSize: 12,
        justifyContent: 'center',
      }}
    >
      <Box dangerouslySetInnerHTML={{ __html: message.html }} />
      <Countdown toDate={endsAt}>
        {(props) => (
          <CountdownDisplay
            hours={props.hours}
            minutes={props.minutes}
            seconds={props.seconds}
          />
        )}
      </Countdown>
    </Flex>
  );
};

MessageStrip.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MessageStrip;
