import React from 'react';
import Client from 'shopify-buy';

const STORE_NAME =
  process.env.SHOPIFY_STORE_NAME || process.env.GATSBY_SHOPIFY_STORE_NAME;
const ACCESS_TOKEN =
  process.env.SHOPIFY_ACCESS_TOKEN || process.env.GATSBY_SHOPIFY_ACCESS_TOKEN;

const domain = STORE_NAME.includes('.')
  ? STORE_NAME
  : `${STORE_NAME}.myshopify.com`;
const client = Client.buildClient({
  domain,
  storefrontAccessToken: ACCESS_TOKEN,
});

export const defaultStoreContext = {
  client,
  isCartOpen: false,
  adding: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  addVariantToCart: () => {},
  addVariantsToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
};

const StoreContext = React.createContext(defaultStoreContext);

export default StoreContext;
