const linkResolver = (doc) => {
  if (doc.type === 'home') {
    return '/';
  }
  if (doc.type === 'blog_home') {
    return '/blog';
  }
  if (doc.type === 'blog') {
    return `/article/${doc.data.category.uid}/${doc.uid}`;
  }
  if (doc.type === 'page') {
    return `/${doc.uid}`;
  }
  // if (doc.type === 'person') {
  //   return `/team/${doc.uid}`;
  // }
  if (doc.type === 'product') {
    return `/product/${doc.uid}`;
  }
  if (doc.type === 'blog_index') {
    return `/blog`;
  }
  if (doc.type === 'exercise_index') {
    return `/exercise`;
  }
  if (doc.type === 'workout_index') {
    return `/workout`;
  }
  return '/';
};

module.exports = linkResolver;
