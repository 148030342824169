export default {
  text: '#000',
  textLight: '#7e718a',
  primary: '#000',
  secondary: '#ccc',
  black: '#000',
  grey: '#646464',
  lightGrey: '#CCC',
  red: '#EE3535',
  bg: {
    grey: '#F4F4F4',
  },
  accentHover: '#55B7A9',
  error: '#f00',
  success: '#0f0',
  dimmed: 'rgba(0, 0, 0, 0.65)',
  menu: {
    grey: '#3D3D3D',
    lightGrey: '#7B7B79',
  },

  brown: '#766A6A',
  lemon: '#ffdf37',
  brand: '#45166a',
  brandLight: '#f5f3f7',
  brandLighter: '#fbfafc',
  brandBright: '#e0d6eb',
  brandDark: '#442266',
  accent: `#72f4bf`,
  lilac: `#8c65b3`,
  white: '#FFF',
  lightTextHover: '#9e97bd',
  background: {
    darkPurple: '#190d3a',
  },
};
