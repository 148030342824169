import React from 'react';
import { Box } from 'theme-ui';

const Menu = (props) => (
  <Box
    {...props}
    sx={{
      display: ['none', null, 'flex'],
      listStyle: 'none',
      margin: 0,
      padding: 0,
      height: 'headerHeight',
    }}
  />
);

export const MobileMenu = ({ visible, ...props }) => (
  <Box
    {...props}
    sx={{
      bg: 'white',
      display: [visible ? 'block' : 'none', null, 'none'],
      listStyle: 'none',
      margin: 0,
      padding: 0,
      position: 'fixed',
      top: 60,
      left: 0,
      right: 0,
      bottom: 0,
      pt: 4,
    }}
  />
);

export default Menu;
