/** @jsx jsx */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { jsx } from 'theme-ui';

class CartIndicator extends Component {
  state = {
    visible: false,
    message: '',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.adding !== this.props.adding) {
      if (this.props.adding) {
        this.setState({
          visible: true,
          message: 'updating cart ...',
        });
      } else {
        if (this.props.itemsInCart > prevProps.itemsInCart) {
          const num = this.props.itemsInCart - prevProps.itemsInCart;
          const message =
            num > 1
              ? `${num} new items have been added to the cart`
              : `${num} new item has been added to the cart`;

          this.setState({ message });

          setTimeout(
            () => this.setState({ visible: false, message: '' }),
            3000
          );
        }
      }
    }
  }

  render() {
    const { visible, message } = this.state;

    return (
      <div
        visible={visible}
        sx={{
          bg: 'accent',
          borderRadius: 'default',
          color: 'brand',
          display: visible ? 'flex' : 'none',
          justifyContent: 'center',
          left: 0,
          top: (theme) => theme.sizes.headerHeight + theme.space[4],
          px: 3,
          py: 2,
          position: 'absolute',
          transform: 'translateX(calc((100% + 16px) * -1))',
          fontSize: 2,
        }}
      >
        {message}
      </div>
    );
  }
}

CartIndicator.propTypes = {
  itemsInCart: PropTypes.number.isRequired,
  adding: PropTypes.bool.isRequired,
};

export default CartIndicator;
