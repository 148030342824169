/** @jsx jsx */

import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { jsx, Button, Flex, Box, Text } from 'theme-ui';
import {
  MdClose,
  MdShoppingCart,
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';

import StoreContext from '../../context/StoreContext';
import CartList from './CartList';
import CartIndicator from './CartIndicator';

const ItemsNumber = ({ children, ...props }) => (
  <span
    className="items-number"
    sx={{
      alignItems: 'center',
      backgroundColor: 'accent',
      borderRadius: '50%',
      color: 'brandDark',
      display: 'flex',
      fontSize: '1.3rem',
      fontWeight: 'bold',
      width: 36,
      height: 36,
      justifyContent: 'center',
    }}
    {...props}
  >
    {children}
  </span>
);

const Cost = styled(Flex)`
  padding: 0 8px 4px;
  :last-child {
    padding-bottom: 0;
  }
  align-items: center;

  span {
    color: ${themeGet('colors.textMild')};
    flex-basis: 60%;
    font-size: 0.9rem;
    text-align: right;
  }

  span.amount {
    color: ${themeGet('colors.brandDark')};
    font-weight: bold;
    flex-basis: 40%;
    text-align: right;
  }
  strong {
    color: ${themeGet('colors.brandDark')};
    flex-basis: 40%;
    text-align: right;
  }
`;

const Total = styled(Cost)`
  border-top: 1px solid ${themeGet('colors.brandBright')};
  color: ${themeGet('colors.lilac')};
  margin-top: 8px;
  padding-top: 12px;
  align-items: center;

  span {
    font-weight: bold;
    text-transform: uppercase;
  }

  strong,
  span {
    color: inherit;
  }
`;

const Cart = ({ status, toggle }) => {
  const {
    client,
    checkout,
    removeLineItem,
    updateLineItem,
    adding,
  } = useContext(StoreContext);

  if (
    checkout.lineItemsSubtotalPrice &&
    checkout.lineItemsSubtotalPrice.amount
  ) {
    console.log('amount:', checkout.lineItemsSubtotalPrice.amount);
  }
  if (checkout.totalPrice) {
    console.log('totalPrice:', checkout.totalPrice);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [className, setClassName] = useState('closed');
  useEffect(() => {
    setClassName(status);
  }, [status]);

  const handleRemove = (itemID) => async (event) => {
    event.preventDefault();
    await removeLineItem(client, checkout.id, itemID);
    setIsLoading(false);
  };

  const handleQuantityChange = (lineItemID) => async (quantity) => {
    if (!quantity) {
      return;
    }
    await updateLineItem(client, checkout.id, lineItemID, quantity);
    setIsLoading(false);
  };

  const itemsInCart = checkout.lineItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  return (
    <div
      className={`${className} ${isLoading ? 'loading' : ''}`}
      sx={{
        width: ['100%', null, 400],
        background: 'white',
        bottom: 0,
        position: 'fixed',
        right: 0,
        top: '-1px',
        transform: 'translateX(100%)',
        transition: 'transform 0.5s',
        willChange: 'transform',
        zIndex: 1000,
        '&.open': {
          transform: 'translateX(0%)',
          boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.15)',
        },

        '&.closed': {
          transform: 'translateX(100%)',
        },
        '::after': {
          bg: 'white',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          content: '""',
          opacity: 0,
          pointerEvents: 'none',
          transition: 'all 250ms',
          display: 'block',
        },

        '&.loading': {
          '::after': {
            opacity: 0.8,
            pointerEvents: 'all',
          },
        },
        '&.covered': {
          display: [null, null, 'none'],
        },
      }}
    >
      <Flex as="header" sx={{ alignItems: 'center', height: 60 }}>
        <Button
          id="cart-toggle-button"
          aria-label={`Shopping cart with ${itemsInCart} items`}
          onClick={toggle}
          sx={{
            background: 'transparent',
            border: 'none',
            borderRadius: 0,
            display: 'flex',
            justifyContent: 'center',
            width: 60,
            height: 60,
            left: 0,
            padding: 0,
            position: 'relative',
            top: 0,
            transform: 'translateX(-100%)',
            transition: 'all 0.5s ease',

            '& span.items-number': {
              position: 'absolute',
              right: '2px',
              top: '2px',
              transform: 'scale(0.6)',
            },

            ':focus': {
              boxShadow: ({ colors }) => `0 0 0 1px ${colors.accent} inset`,
            },

            '.open &': {
              backgroundColor: 'brand',
              color: 'white',
              transform: ['translateX(0)', null, 'translateX(-100%)'],
              transition: ['none', null, 'all 0.5s ease'],
              svg: {
                fill: 'white',
              },
            },

            '& svg': {
              height: '28px !important',
              margin: '0 !important',
              width: '28px !important',
              fill: 'brand',
            },
          }}
        >
          {status === 'open' ? (
            <MdClose />
          ) : (
            <>
              <MdShoppingCart />
              {itemsInCart > 0 && <ItemsNumber>{itemsInCart}</ItemsNumber>}
            </>
          )}
        </Button>
        <CartIndicator itemsInCart={itemsInCart} adding={adding} />
        <Box sx={{ flex: 1 }}>
          <Text
            as="h2"
            my={0}
            sx={{ ml: [3, null, 0], fontSize: 3, color: 'brand' }}
          >
            Your Cart
          </Text>
        </Box>
        <div
          sx={{
            display: 'flex',
            alignItems: 'center',

            fontSize: '0.8rem',
            lineHeight: 1.2,
            textAlign: 'right',

            '.items-number': {
              mx: 3,
            },
          }}
        >
          items
          <br />
          in cart<ItemsNumber>{itemsInCart}</ItemsNumber>
        </div>
      </Flex>
      <Box p={3}>
        <CartList
          items={checkout.lineItems}
          handleRemove={handleRemove}
          updateQuantity={handleQuantityChange}
          setCartLoading={setIsLoading}
          isCartLoading={isLoading}
        />
        <Box mt={3}>
          {checkout.lineItemsSubtotalPrice &&
            checkout.lineItemsSubtotalPrice.amount && (
              <Cost>
                <span>Subtotal:</span>{' '}
                <span className="amount">
                  {checkout.currencyCode}{' '}
                  {checkout.lineItemsSubtotalPrice.amount}
                </span>
              </Cost>
            )}
          {checkout.discountApplications &&
            checkout.discountApplications.length > 0 && (
              <Cost>
                <span>Discount:</span>
                <span className="amount">
                  {checkout.discountApplications[0].title}
                </span>
              </Cost>
            )}
          {checkout.totalPrice &&
            checkout.lineItemsSubtotalPrice &&
            checkout.lineItemsSubtotalPrice.amount && (
              <Cost>
                <span>You save:</span>{' '}
                <span className="amount">
                  {checkout.currencyCode}{' '}
                  {(
                    parseFloat(checkout.totalPrice) -
                    parseFloat(checkout.lineItemsSubtotalPrice.amount)
                  ).toFixed(2)}
                </span>
              </Cost>
            )}

          {/* <Cost>
            <span>Subtotal:</span>{' '}
            <strong>
              {checkout.currencyCode} {checkout.subtotalPrice}
            </strong>
          </Cost> */}
          {/* <Cost>
            <span>Taxes:</span> <strong>{checkout.totalTax}</strong>
          </Cost> */}
          {/* <Cost>
            <span>Shipping:</span> <strong>FREE</strong>
          </Cost> */}
          <Flex
            sx={{
              px: 2,
              pb: 3,
              alignItems: 'center',
              '.label': {
                color: 'textMild',
                flexBasis: '60%',
                fontSize: '0.9rem',
                textAlign: 'right',
              },
              '.value': {
                color: 'lilac',
                flexBasis: '40%',
                textAlign: 'right',
                fontSize: 0,
              },
            }}
          >
            <p className="label">Shipping:</p>
            <p className="value">calculated at next step</p>
          </Flex>
          <Total>
            <span>Total Price:</span>
            <strong>
              {checkout.currencyCode} {checkout.totalPrice}
            </strong>
          </Total>
        </Box>
        <Button
          id="cart-checkout-button"
          mt={4}
          variant="cart.checkout"
          onClick={handleCheckout}
        >
          Check out <MdArrowForward />
        </Button>
        <Button
          id="cart-back-button"
          mt={3}
          variant="cart.outline"
          onClick={toggle}
        >
          <MdArrowBack />
          Back to shopping
        </Button>
      </Box>
    </div>
  );
};

export default Cart;
