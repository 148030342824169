/* eslint-disable no-lonely-if */
/** @jsx jsx */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import { jsx, Flex, Button } from 'theme-ui';

const isBrowser = typeof window !== 'undefined';

const RedirectPopup = ({ message }) => {
  const [visible, setVisible] = useState(false);
  const [redirect, setRedirect] = useState({
    label: 'USA',
    href: '/en-us',
    selectedStore: 'en-us',
  });

  useEffect(async () => {
    const selectedStore = Cookies.get('selectedStore');
    if (selectedStore === undefined) {
      let currentStore = '';
      if (isBrowser) {
        currentStore = window.location.href.split('/')[3];

        // Check IPData
        const response = await fetch(
          'https://api.ipdata.co?api-key=bd52dff775f7ae0eff2109c085af4d13cb228ddd302f0a16abd92607',
          {
            headers: { Accept: 'application/json' },
          }
        );
        const json = await response.json();
        const { country_code: countryCode } = json;

        if (countryCode === 'US') {
          if (currentStore !== 'en-us') {
            // Recommend USA
            setVisible(true);
          } else {
            Cookies.set('selectedStore', redirect.selectedStore, {
              expires: 7,
            });
          }
        } else {
          if (currentStore === 'en-us') {
            // Recommend UK
            setRedirect({ label: 'UK', href: '/', selectedStore: '' });
            setVisible(true);
          } else {
            Cookies.set('selectedStore', '', {
              expires: 7,
            });
          }
        }
      }
    }
  }, []);

  const handleDismiss = () => {
    setVisible(false);
  };

  const handleClick = () => {
    if (isBrowser) {
      Cookies.set('selectedStore', redirect.selectedStore, { expires: 7 });
      window.location.href = redirect.href;
    }
  };

  return (
    visible && (
      <Flex
        sx={{
          flexDirection: ['column', null, 'row'],
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          width: '100%',
          background: 'linear-gradient(45deg, #45166a, #83157b 75%, #bc158a)',
          // bg: 'brand',
          color: 'white',
          p: 4,
          textAlign: 'center',
          fontSize: 3,
          // fontWeight: 'bold',
          zIndex: 10000,
        }}
      >
        <p sx={{ mr: [0, null, 4], mb: [4, null, 0] }}>{message}</p>
        <Flex>
          <Button
            variant="primary"
            sx={{ bg: 'white', color: 'brand', fontWeight: 'bold' }}
            onClick={handleClick}
          >
            {redirect.label}
          </Button>
          <Button
            variant="primary"
            sx={{
              bg: 'transparent',
              color: 'rgba(255, 255, 255, 0.6)',
              ':hover': { color: 'white' },
            }}
            onClick={handleDismiss}
          >
            Dismiss
          </Button>
        </Flex>
      </Flex>
    )
  );
};

RedirectPopup.propTypes = {
  message: PropTypes.string.isRequired,
};

export default RedirectPopup;
