/** @jsx jsx */

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import shortid from 'shortid';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaYoutube,
} from 'react-icons/fa';

import { jsx, Flex, Box, Text } from 'theme-ui';

// import { Flex, Box } from '../shared/Elements';
import Logo from './Logo';
import colors from '../../tokens/colors';

import linkResolver from '../../utils/linkResolver';

const Container = styled(Flex)`
  max-width: ${themeGet('dimensions.contentWidth')};
  margin: 0 auto;
`;

const Social = styled(Box)`
  color: ${themeGet('colors.white')};

  a {
    font-size: 24px;
    color: ${themeGet('colors.white')};
    opacity: 0.5;
  }

  a:hover {
    opacity: 1;
  }

  svg {
    margin-right: 16px;
  }
`;

const Title = styled(`h2`)`
  color: ${themeGet('colors.white')};
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
`;
const Section = ({ children, ...props }) => (
  <Box
    sx={{
      a: {
        color: 'white',
        textDecoration: 'none',
        fontSize: 0,
      },
      'a:hover': {
        color: 'lightTextHover',
      },
    }}
    {...props}
  >
    {children}
  </Box>
);
const Section1 = styled(Box)`
  a {
    color: ${themeGet('colors.white')};
    text-decoration: none;
    font-size: 0.75rem;
  }

  a:hover {
    color: ${themeGet('colors.lightTextHover')};
  }

  // & > ${Box} {
  //   margin-bottom: 8px;
  // }
`;

const MenuItem = styled('li')`
  margin-top: 8px;
`;

const Footer = ({ data }) => {
  const { logo, footerBody, copyright } = data;

  return (
    <Box as="footer" sx={{ bg: 'background.darkPurple' }}>
      <Flex
        mx="auto"
        sx={{
          maxWidth: 'contentWidth',
          flexDirection: ['column', null, 'row'],
          justifyContent: 'space-between',
          px: [5, 5, 0],
          py: 5,
        }}
      >
        <Section mt={[5, 5, 0]}>
          <Img fixed={logo.localFile.childImageSharp.fixed} />
        </Section>

        {/* <Flex
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-around"
          flex={1}
          ml={[0, 0, 5]}
        > */}
        {footerBody.map((group) => {
          const { items } = group;
          const { label: columnTitle } = group.primary;
          return (
            <Section key={shortid.generate()} sx={{ my: [4, null, 0], p: 0 }}>
              <Text
                as="h2"
                mb={3}
                sx={{
                  color: 'white',
                  opacity: 0.5,
                  textTransform: 'uppercase',
                  fontSize: 1,
                  fontWeight: 'bold',
                }}
              >
                {columnTitle}
              </Text>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {items.map((item) => {
                  const { label, link_url: linkURL } = item;
                  return (
                    <MenuItem key={shortid.generate()}>
                      <Link to={linkResolver(linkURL)}>{label}</Link>
                    </MenuItem>
                  );
                })}
              </ul>
            </Section>
          );
        })}

        <Box
          sx={{
            a: {
              fontSize: 4,
              color: 'white',
              opacity: 0.5,
            },

            'a:hover': {
              opacity: 1,
            },

            svg: {
              marginRight: '16px',
            },
          }}
        >
          <Text
            as="h2"
            mb={3}
            sx={{
              color: 'white',
              opacity: 0.5,
              textTransform: 'uppercase',
              fontSize: 1,
              fontWeight: 'bold',
            }}
          >
            Follow us
          </Text>
          <a
            href="https://www.facebook.com/Originalkettlebell/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare />
          </a>
          {/* <a href="/">
            <FaTwitter />
          </a> */}
          <a
            href="https://www.instagram.com/original_kettlebell/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.youtube.com/channel/UCy67jdMC9aYziLX9CDy1ENQ"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube />
          </a>
        </Box>
      </Flex>

      <div
        sx={{
          p: 3,
          textAlign: 'center',
          fontSize: 0,
          color: 'grey',
          backgroundColor: '#110532',
        }}
      >
        Only one discount can be applied at any one time.
      </div>
      <div
        sx={{
          p: 3,
          textAlign: 'center',
          fontSize: 0,
          color: 'grey',
          backgroundColor: '#110532',
        }}
      >
        © {new Date().getFullYear()}. {copyright}
      </div>
    </Box>
  );
};

Footer.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Footer;
