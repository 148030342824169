import colors from './colors';

const breakpoints = ['40em', '56em', '64em'];

const fonts = {
  body:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
  heading: '"Avenir Next", sans-serif',
  monospace: 'Menlo, monospace',
};

const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 96];
const fontWeights = {
  light: 200,
};

const space = [0, 4, 8, 16, 32, 64, 128, 256, 512];

const sizes = {
  container: 1440,
  contentWidth: 1200,
  copyWidth: 700,
  copyExtendedWidth: 900,
  cardWidth: 350,
  headerHeight: 60,
};

const layout = {
  content: {
    maxWidth: 'contentWidth',
    mx: 'auto',
  },
  copy: {
    maxWidth: 'copyWidth',
    mx: 'auto',
  },
  copyExtended: {
    maxWidth: 'copyExtendedWidth',
    mx: 'auto',
  },
};

const text = {
  heading: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  imageDescription: {
    fontSize: 1,
    fontStyle: 'italic',
    color: 'lightGrey',
    my: 3,
  },
  cardTitle: {
    color: 'white',
    fontWeight: 'bold',
    p: 3,
  },
  cardContent: {
    bg: 'bg.grey',
    color: 'grey',
    p: 3,
    lineHeight: 1.8,
    height: '100%',
  },
  blogItem: {
    title: {
      color: 'lightGrey',
      fontSize: 4,
      fontWeight: 'normal',
      mb: 3,
    },
    titleBig: {
      color: 'accent',
      fontSize: 6,
      fontWeight: 'normal',
      textTransform: 'uppercase',
    },
  },
  contact: {
    name: {
      fontSize: 'inherit',
    },
    title: {
      fontSize: 1,
    },
  },
  error: {
    fontSize: 0,
    color: 'accent',
  },
  thankyou: {
    title: {
      fontSize: 5,
      fontWeight: 'bold',
      color: 'white',
    },
    subtitle: {
      fontSize: 3,
      color: 'white',
    },
  },
  megamenu: {
    section: {
      fontSize: 4,
      fontWeight: 'bold',
      color: 'white',
    },
  },
  person: {
    name: {
      fontSize: 2,
      fontWeight: 'bold',
      color: 'black',
    },
    nameBig: {
      fontSize: 3,
      fontWeight: 'bold',
      color: 'black',
    },
    title: {
      fontSize: 2,
      color: 'black',
    },
    titleBig: {
      fontSize: 3,
      color: 'black',
    },
  },
  location: {
    normal: {
      fontSize: 1,
    },
    highlight: {
      fontSize: 1,
      fontWeight: 'bold',
      color: 'accent',
    },
  },
  footerTitle: {
    color: 'white',
    opacity: 0.5,
    textTransform: 'uppercase',
    fontSize: 1,
    fontWeight: 'bold',
  },
};

const styles = {
  spinner: {
    color: 'background',
    size: 24,
    strokeWidth: 2,
    mt: 4,
  },
};

const buttons = {
  primary: {
    color: 'white',
    bg: 'brand',
    // textTransform: 'uppercase',
    borderRadius: 'large',
    fontSize: 2,
    // fontWeight: 'bold',
    px: 4,
    '&:hover': {
      // bg: 'accentHover',
      svg: {
        fill: 'accent',
      },
    },
    svg: {
      height: 18,
      width: 18,
    },
  },
  fancyPrimary: {
    borderRadius: 'large',

    position: 'relative',
    zIndex: 0,
    ':before': {
      content: '""',
      position: 'absolute',
      bg: '#72f4bf',
      top: 0,
      left: 0,
      borderRadius: 'large',
      width: '100%',
      height: '100%',
      opacity: '0.5',
      zIndex: -1,
      transform: 'scale(1.0)',
      transition: 'all 0.3s cubic-bezier(0.16, 0.8, 0.66, 1.54)',
    },
    ':hover:before': {
      transform: 'scaleX(1.09) scaleY(1.32)',
    },
  },
  submit: {
    color: 'background',
    bg: 'accent',
    borderRadius: 'default',
    fontSize: 2,
    '&:hover': {
      color: 'accentHover',
    },
  },
  outline: {
    color: 'brand',
    bg: 'transparent',
    fontSize: 1,
    fontWeight: 'bold',
    // textTransform: 'uppercase',
    border: (theme) => `1px solid ${theme.colors.brand}`,
  },
  outlineDark: {
    color: 'white',
    bg: 'transparent',
    fontSize: 1,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    border: (theme) => `3px solid ${theme.colors.white}`,
    '&:hover': {
      color: 'accent',
      border: (theme) => `3px solid ${theme.colors.accent}`,
    },
  },
  category: {
    color: 'white',
    bg: 'brand',
    fontSize: 1,
    fontWeight: 'bold',
  },
  empty: {
    bg: 'transparent',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 30,
    height: 40,
    margin: 0,
    padding: 0,
    color: 'brand',
  },
  cart: {
    toggle: {
      background: 'brand',
    },
    checkout: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      color: 'brand',
      bg: 'accent',
      borderRadius: 'large',
      fontSize: 2,
      border: ({ colors }) => `1px solid ${colors.accent}`,
      svg: {
        ml: 2,
        height: 20,
        width: 20,
      },
      position: 'relative',
      zIndex: 0,
      ':before': {
        content: '""',
        position: 'absolute',
        bg: '#72f4bf',
        top: 0,
        left: 0,
        borderRadius: 'large',
        width: '100%',
        height: '100%',
        opacity: '0.5',
        zIndex: -1,
        transform: 'scale(1.0)',
        transition: 'all 0.3s cubic-bezier(0.16, 0.8, 0.66, 1.54)',
      },
      ':hover:before': {
        transform: 'scaleX(1.04) scaleY(1.32)',
      },
    },
    outline: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      color: 'brand',
      bg: 'transparent',
      fontSize: 2,
      borderRadius: 'large',
      border: ({ colors: c }) => `1px solid ${c.brand}`,
      '&:hover': {
        svg: {
          fill: 'accent',
        },
      },
      svg: {
        mr: 2,
        height: 20,
        width: 20,
      },
    },
    remove: {
      bg: 'transparent',
      border: (theme) => `1px dotted ${theme.colors.textLight}`,
      display: 'flex',
      flexShrink: 0,
      width: 44,
      height: 44,
      justifyContent: 'center',
      marginRight: '4px',
      padding: 0,

      svg: {
        height: 24,
        margin: 0,
        width: 24,
        fill: 'textLight',
      },
      ':hover': {
        border: (theme) => `1px solid ${theme.colors.textLight}`,
        svg: {
          fill: 'brand',
        },
      },
    },
  },
};

const forms = {
  input: {
    fontSize: 0,
    border: 'none',
    outline: 'none',
    borderRadius: 0,
    px: 0,
    borderBottom: (theme) => `1px solid ${theme.colors.menu.lightGrey}`,
  },
  textarea: {
    fontSize: 0,
    px: 0,
    outline: 'none',
    border: 'none',
    borderRadius: 0,
    borderBottom: (theme) => `1px solid ${theme.colors.menu.lightGrey}`,
  },
};

const radii = {
  small: 2,
  default: 4,
  big: 16,
  large: 32,
};

const links = {
  button: {
    color: 'background',
    bg: 'accent',
    textTransform: 'uppercase',
    textDecoration: 'none',
    borderRadius: 'default',
    fontSize: 2,
    px: 4,
    py: 2,
    lineHeight: 3,
    '&:hover': {
      bg: 'accentHover',
    },
  },
};

const cards = {
  positiveNegative: {
    minWidth: ['100%', null, 'cardWidth'],
    maxWidth: ['100%', null, 'cardWidth'],
    borderRadius: 'big',
    overflow: 'hidden',
  },
};

const borders = {
  lightGreyBorder: `1px solid ${colors.brandLight}`,
  transparentBorder2px: `2px solid transparent`,
  brandBorder2px: `2px solid ${colors.brand}`,
};

export default {
  borders,
  cards,
  radii,
  forms,
  buttons,
  links,
  styles,
  text,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  breakpoints,
  space,
  sizes,
  layout,
};
