export default {
  text: '#333333',
  textLight: '#7e718a',
  lightest: '#ffffff',
  light: '#f2f2f2',
  brandLight: '#f5f3f7',
  brandLighter: '#fbfafc',
  brandDark: '#442266',
  brandBright: '#e0d6eb',
  brand: '#45166a',
  lilac: `#8c65b3`,
  accent: `#72f4bf`,
  error: `#ec1818`,
  lemon: `#ffdf37`,
  zzz: '#ECBAFF',
  grey: '#979797',
  brown: '#766A6A',
  white: '#FFF',
  lightTextHover: '#9e97bd',
  background: {
    darkPurple: '#190d3a',
  },
};
