/** @jsx jsx */

import React from 'react';
import { jsx } from 'theme-ui';

const MenuItem = ({ children }) => (
  <li
    sx={{
      px: 3,
      py: [3, null, 0],
      height: ['auto', null, '100%'],
      borderBottom: 'transparentBorder2px',
      fontSize: [1, null, 0],
      fontWeight: 'bold',
      display: ['flex', null, 'list-item'],
      justifyContent: 'center',

      '& > a': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'brand',
        textDecoration: 'none',
        textTransform: 'uppercase',
        paddingBottom: '1px',
        height: ['100%'],
      },

      '&:hover': {
        borderBottom: 'brandBorder2px',
      },
    }}
  >
    {children}
  </li>
);

export default MenuItem;